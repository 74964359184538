<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Proyecciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Proyecciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA EL MODAL DE PROYECCION EXPORT -->
                      <button
                        type="button"
                        class="btn bg-gradient-primary shadow"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.proyeccionHidrocarburos.index'
                          )
                        "
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-success shadow"
                        data-toggle="modal"
                        data-target="#modal_form_proyeccionHidrocarburos_export"
                        @click="
                          $refs.proyeccionHidrocarburosExport.limpiarFiltros()
                        "
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.proyeccionHidrocarburos.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-primary shadow"
                        @click="create()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.proyeccionHidrocarburos.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="proyeccionHidrocarburos"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th class="text-center">
                        Id
                        <input
                          type="text"
                          class="form-control form-control-sm text-center"
                          v-model="filtros.id"
                          @change="getIndex"
                        />
                      </th>
                      <th class="text-center">
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @change="getIndex"
                        />
                      </th>
                      <th class="text-center">
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @change="getIndex"
                        />
                      </th>
                      <th class="text-center">
                        Bloque
                        <label for="bloque">Bloque</label>
                        <v-select
                          class="form-control form-control-sm p-0"
                          placeholder="Bloque"
                          label="nombre"
                          v-model="bloque"
                          :options="listasForms.bloques"
                          @input="selectBloque"
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                        >
                        </v-select>
                      </th>
                      <th class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">
                        Acción
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(proyeccion,
                      index) in proyeccionHidrocarburos.data"
                      :key="index"
                    >
                      <td class="text-center">{{ proyeccion.id }}</td>
                      <td class="text-center">{{ proyeccion.fecha_ini }}</td>
                      <td class="text-center">{{ proyeccion.fecha_fin }}</td>
                      <td class="text-center">
                        {{ proyeccion.bloque.nombre }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            proyeccion.estado == 1
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                        >
                          {{ proyeccion.nEstado }}
                        </span>
                      </td>
                      <td class="text-center">
                        <div class="btn-group shadow">
                          <!-- <button
                            type="button"
                            class="btn bg-gradient-info btn-sm"
                            @click="edit(proyeccion.id, 2)"
                          >
                            <i class="fas fa-eye"></i>
                          </button> -->
                          <button
                            type="button"
                            class="btn bg-gradient-navy btn-sm"
                            @click="edit(proyeccion.id, 1)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.proyeccionHidrocarburos.edit'
                              )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-gradient-danger btn-sm"
                            @click="destroy(proyeccion.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.proyeccionHidrocarburos.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="proyeccionHidrocarburos.total">
                  <p>
                    Mostrando del <b>{{ proyeccionHidrocarburos.from }}</b> al
                    <b>{{ proyeccionHidrocarburos.to }}</b> de un total:
                    <b>{{ proyeccionHidrocarburos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="proyeccionHidrocarburos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right mb-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ProyeccionHidrocarburosExport ref="proyeccionHidrocarburosExport" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import ProyeccionHidrocarburosExport from "./ProyeccionHidrocarburosExport";

export default {
  name: "ProyeccionesHidrocarburosIndex",
  components: {
    pagination,
    Loading,
    vSelect,
    ProyeccionHidrocarburosExport,
  },
  data() {
    return {
      proyeccionHidrocarburos: {},
      hoy: new Date(),
      cargando: false,
      bloque: [],
      filtros: {
        id: null,
        fecha_ini: null,
        fecha_fin: null,
        bloque_id: null,
      },
      listasForms: {
        estados: [],
        bloques: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/proyeccionHidrocarburos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.proyeccionHidrocarburos = response.data;
        });
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/ProyeccionesForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(proyeccion_id) {
      return this.$router.push({
        name: "/Hidrocarburos/ProyeccionesForm",
        params: { accion: "Editar", id: proyeccion_id },
      });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    async selectBloque() {
      this.filtros.bloque_id = null;
      if (this.bloque) {
        this.filtros.bloque_id = await this.bloque.id;
      }
      this.getIndex();
    },

    destroy(proyeccion_id) {
      this.$swal({
        title: "Esta seguro de eliminar la proyección: " + proyeccion_id + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(
              "/api/hidrocarburos/proyeccionHidrocarburos/" + proyeccion_id
            )
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Se eliminó la proyección exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getIndex();
            });
        }
      });
    },
  },
  mounted() {
    this.getEstados();
    this.getBloques();
    this.getIndex();
  },
};
</script>
